import React from "react";
import { Grid } from "@mui/material";
import AppSlider from "../../AppSlider";
import { MEDIA_LIST } from "../../../data/StaticList";

function Press() {
  return (
    <Grid sx={{ padding: { xs: "40px 0 20px 0", md: "80px 0" } }}>
      <AppSlider list={MEDIA_LIST} title="In the press" width={240} pointer />
    </Grid>
  );
}

export default Press;
