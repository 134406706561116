import { Grid, Typography } from "@mui/material";
import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image, { StaticImageData } from "next/image";
import Link from "next/link";

function AppSlider({
  list,
  title,
  directionLTR,
  width,
  numbers,
  titleMargin,
  sliderPad,
  pointer,
}: {
  list: {
    name: string;
    icon: StaticImageData;
    redirectLink: string;
  }[];
  title?: string;
  directionLTR?: boolean;
  width?: number;
  numbers?: number;
  titleMargin?: string;
  sliderPad?: string;
  pointer?: boolean;
}) {
  var settings: Settings = {
    infinite: true,
    speed: 1500,
    slidesToShow: numbers ? numbers : 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false,
    arrows: false,
    easing: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Grid
      sx={{
        padding: sliderPad ? sliderPad : "0",
      }}
      className="container"
    >
      {title ? (
        <Grid
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            padding: "8px 0",
            margin: titleMargin ? titleMargin : "auto",
          }}
        >
          <Typography
            sx={{
              background: "white",
              padding: "0 8px",
              zIndex: 1,
              fontWeight: "bold",
              fontSize: { xs: "24px", md: "32px" },
            }}
          >
            {title}
          </Typography>
          <Grid
            style={{
              position: "absolute",
              height: 1,
              width: "100%",
              background: "lightgray",
              top: "calc(50% + 0.5px)",
              zIndex: 0,
            }}
          ></Grid>
        </Grid>
      ) : null}

      <Grid
        style={{
          padding: "8px 0",
          transform: directionLTR ? "rotate(180deg)" : "rotate(0deg)",
        }}
      >
        <Slider {...settings}>
          {list.map((m, i) => {
            return (
              <div key={i}>
                {m?.redirectLink?.length > 0 ? (
                  <Link href={m.redirectLink} rel="preload">
                    <Image
                      width={width ? width : 140}
                      height={140}
                      src={m.icon}
                      alt={m.name}
                      style={{
                        // width: width ? width : 140,
                        maxWidth: width ? width : 140,
                        margin: "auto",
                        border: "none",
                        outline: "none",
                        transform: directionLTR
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        objectFit: "contain",
                        //   maxHeight: "70px !important",
                        cursor: pointer ? "pointer" : "normal",
                      }}
                      className="app-slider-img"
                      loading="lazy"
                    />
                  </Link>
                ) : (
                  <Image
                    src={m.icon}
                    alt={m.name}
                    width={width ? width : 140}
                    height={140}
                    style={{
                      width: width ? width : 140,
                      maxWidth: width ? width : 140,
                      margin: "auto",
                      border: "none",
                      outline: "none",
                      transform: directionLTR
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      objectFit: "contain",
                      //   maxHeight: "70px !important",
                      cursor: pointer ? "pointer" : "normal",
                    }}
                    className="app-slider-img"
                    loading="lazy"
                  />
                )}
              </div>
            );
          })}
        </Slider>
      </Grid>
    </Grid>
  );
}

export default AppSlider;
